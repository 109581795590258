import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  title = 'Navi Savi Admin';

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  goToStoryManagement() {
    this.router.navigate(['admin', 'story', 'management']);
  }

  goToAttractionManagement() {
    this.router.navigate(['admin', 'attraction', 'management']);
  }

  goToStoryApproval() {
    this.router.navigate(['admin', 'story-approval']);
  }
}
