import { Routes } from '@angular/router';
import { AttractionComponent } from './attraction.component';
import { AttractionFormComponent } from './attraction-form/attraction-form.component';
import { AuthGuard } from '../auth/auth.guard';
import { AttractionAddComponent } from './attraction-add/attraction-add.component';
import { AttractionEditComponent } from './attraction-edit/attraction-edit.component';
import { AttractionManagementComponent } from './attraction-management/attraction-management.component';

export const attractionRoutes: Routes = [{
  path: 'attraction',
  component: AttractionComponent,
  children: [
    { path: 'form', component: AttractionFormComponent, canActivate: [AuthGuard] },
    { path: 'add', component: AttractionAddComponent, canActivate: [AuthGuard] },
    { path: 'edit/:id', component: AttractionEditComponent, canActivate: [AuthGuard] },
    { path: 'management', component: AttractionManagementComponent, canActivate: [AuthGuard] },
  ]
}];
