export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyA5FmX-rHIDHda0R5Mtmb1IM0H1dZNo2fw',
    authDomain: 'navi-savi.firebaseapp.com',
    databaseURL: 'https://navi-savi.firebaseio.com',
    projectId: 'navi-savi',
    storageBucket: 'navi-savi.appspot.com',
    messagingSenderId: '330300186761',
    appId: '1:330300186761:web:12a28eabc84486d6'
  },
  cloudinary: {
    cloudName: 'navi-savi',
    uploadPreset: 'story-default',
    uploadFolder: 'stories'
  },
  google: {
    // TODO: Change to NaviSavi restricted API key
    apiKey: 'AIzaSyDUwpgVkdTPLrHT_h02gTrZ-ah1KdwypIM'
  },
  algolia: {
    appId: 'KO2KKIGT4B',
    searchApiKey: '40f02b93d23cbc5bd538121ea8e20cfc',
    attractionIndexName: 'prod_attractions',
    storyIndexName: 'prod_stories',
    userIndexName: 'prod_users'
  }
};
