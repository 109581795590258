import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { StoryModel } from '../story.model';
import { environment } from '../../../environments/environment';
import { StoryService } from '../story.service';

@Component({
  selector: 'app-story-video-dialog',
  templateUrl: './story-video-dialog.component.html',
  styleUrls: ['./story-video-dialog.component.scss']
})
export class StoryVideoDialogComponent implements OnInit {
  public onCloseContinue: EventEmitter<any> = new EventEmitter();
  public onCloseDone: EventEmitter<any> = new EventEmitter();

  public concatenatedVideoUrl: string;
  public showContinue: boolean;
  private story: StoryModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storyService: StoryService
  ) {
    this.story = data.story;
    this.showContinue = data.showContinue;
    this.concatenatedVideoUrl = this.storyService.getConcatenatedVideo(this.story.videos);
  }

  ngOnInit() {
  }

  closeContinue() {
    this.onCloseContinue.emit();
  }

  closeDone() {
    this.onCloseDone.emit();
  }
}
