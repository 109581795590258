import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttractionFormComponent } from './attraction-form/attraction-form.component';
import { AttractionComponent } from './attraction.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AttractionAddDialogComponent } from './attraction-add-dialog/attraction-add-dialog.component';
import { AttractionAddComponent } from './attraction-add/attraction-add.component';
import { AttractionManagementComponent } from './attraction-management/attraction-management.component';
import { AttractionEditComponent } from './attraction-edit/attraction-edit.component';
import { AttractionSearchComponent } from './attraction-search/attraction-search.component';

@NgModule({
  declarations: [
    AttractionFormComponent,
    AttractionComponent,
    AttractionAddDialogComponent,
    AttractionAddComponent,
    AttractionManagementComponent,
    AttractionEditComponent,
    AttractionSearchComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    RouterModule,
    AgmCoreModule,
    MatGoogleMapsAutocompleteModule,
  ],
  exports: [
    AttractionSearchComponent
  ]
})
export class AttractionModule { }
