import { BaseModel } from "./base.model";
import { JsonProperty } from './json-map.util';

export class UserMetadataModel extends BaseModel {
  creationTime: Date = undefined;
  lastSignInTime: Date = undefined;
}

export class UserModel extends BaseModel {
  displayName: string = undefined;
  email: string = undefined;
  photoURL: string = undefined;
  uid: string = undefined;
  username: string = undefined;
  bio: string = undefined;

  toRawObject() {
    const obj = super.toRawObject();
    return obj;
  }
}

export class UserSearchModel extends UserModel {
  objectID: string;
}
