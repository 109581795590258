import { UserSearchModel, UserModel } from './../../shared/user.model';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Location as GMALocation } from '@angular-material-extensions/google-maps-autocomplete';
import { UserService } from '../../shared/user.service';
import { Subscription, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { StoryModel } from '../story.model';
import { User } from 'firebase';
import { MatDialogRef, MatDialog } from '@angular/material';
import { AttractionAddComponent } from '../../attraction/attraction-add/attraction-add.component';
import { AttractionModel, AttractionSearchModel } from '../../attraction/attraction.model';
import { unescapeIdentifier } from '@angular/compiler';
import { CloudMediaModel } from '../../shared/cloud-media.model';
import { AttractionService } from 'src/app/attraction/attraction.service';
import { AttractionEditComponent } from '../../attraction/attraction-edit/attraction-edit.component';

@Component({
  selector: 'app-story-form',
  templateUrl: './story-form.component.html',
  styleUrls: ['./story-form.component.scss']
})
export class StoryFormComponent implements OnInit, OnChanges {
  @Output() submitStory: EventEmitter<StoryModel> = new EventEmitter();
  @Input() story: StoryModel;
  @Input() user: UserModel;
  @Input() shouldRefreshForm: boolean;

  storyForm = this.fb.group({
    user: [null, Validators.required],
    userUid: null,
    name: [null, Validators.required],
    attractionId: null,
    attraction: [null, Validators.required],
    tags: null,
    price: null,
    videos: null,
    published: true
  });

  // TODO: Move to const class
  tagList = ['Tag 1', 'Tag 2', 'Tag 3'];
  priceList = ['', '$', '$$', '$$$', '$$$$', '$$$$$'];

  // Autocomplete user
  userSearchResults: UserSearchModel[] = [];
  currentSelectedUser: UserModel;
  userSearchObservable: Subscription;
  updatingUser = false;

  // Autocomplete/add attraction
  attractionSearchResults = [];
  attractionDisplayValue = '';

  videos: Array<any> = [];

  addDialogRef: MatDialogRef<AttractionAddComponent>;
  editDialogRef: MatDialogRef<AttractionEditComponent>;
  attractionSubscription: Subscription;
  attraction: AttractionModel = null;

  hasOpenedVideo = false;
  hasOpenedAttraction = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private dialog: MatDialog,
    private attractionService: AttractionService
  ) {

  }

  ngOnInit() {
    if (this.story) {
      this.storyForm.patchValue(this.story);
      this.videos = this.story.videos;
      this.attraction = this.story.attraction;
      this.setCurrentUser(this.story.user);
    } else if (this.user) {
      this.setCurrentUser(this.user);
    }

    // Subscribe to autocomplete input changes to search users
    this.userSearchObservable = this.storyForm.controls.user.valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value) => {
      console.log('Sub data', value);
      this.userService.searchUsers(value).then((users) => {
        console.log(users);
        this.userSearchResults = users;
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // When continue with same user is chosen from add story, refresh the form and form values.
    if (changes.shouldRefreshForm && changes.shouldRefreshForm.currentValue) {
      this.attraction = null;
      this.story = null;
      this.videos = [];
      this.storyForm.reset({ user: this.user });
      this.hasOpenedAttraction = false;
      this.hasOpenedVideo = false;
    }
  }

  /**
   * Handle behavior after a user is selected in the material autocomplete
   * @param user Selected user
   */
  async handleSelectUser(userModel: UserSearchModel) {
    console.log('User selected', userModel);
    this.updatingUser = true;
    try {
      await this.setCurrentUser(userModel);
    } catch (e) {
      console.error(e);
    }
    this.updatingUser = false;
    return this.currentSelectedUser;
  }

  /**
   * Displays the add attraction component in a dialog
   */
  showAttractionFormDialog() {
    this.addDialogRef = this.dialog.open(AttractionAddComponent);
    this.attractionSubscription = this.addDialogRef.componentInstance.addAttraction
      .subscribe((attraction: {id: string, model: AttractionModel }) => {
        this.storyForm.patchValue({ attractionId: attraction.id, attraction: attraction.model });
        this.attraction = attraction.model;
        // If the story doesn't have a name yet and the attraction does, set the story name
        if (this.attraction.hasOwnProperty('name') && !!this.attraction.name && (!this.story || !this.story.name)) {
          this.storyForm.patchValue({
            name: this.attraction.name
          });
        }
        this.addDialogRef.close();
      });

    this.addDialogRef.afterClosed().subscribe(() => {
      this.hasOpenedAttraction = true;
      this.attractionSubscription.unsubscribe();
    });
  }

  /**
   * Displays the edit attraction component in a dialog
   */
  showEditAttractionFormDialog() {
    this.editDialogRef = this.dialog.open(AttractionEditComponent);
    this.editDialogRef.componentInstance.attraction = this.attraction;
    console.log(`attractionId: `, this.story.attractionId);
    this.editDialogRef.componentInstance.attractionId = this.story.attractionId;
    this.editDialogRef.componentInstance.editFromStoryForm = true;
    this.attractionSubscription = this.editDialogRef.componentInstance.editAttraction
      .subscribe((attraction: {id: string, model: AttractionModel }) => {
        this.storyForm.patchValue({ attractionId: attraction.id, attraction: attraction.model });
        this.attraction = attraction.model;
        // If the story doesn't have a name yet and the attraction does, set the story name
        if (this.attraction.hasOwnProperty('name') && !!this.attraction.name && (!this.story || !this.story.name)) {
          this.storyForm.patchValue({
            name: this.attraction.name
          });
        }
        this.editDialogRef.close();
      });

    this.editDialogRef.afterClosed().subscribe(() => {
      this.hasOpenedAttraction = true;
      this.attractionSubscription.unsubscribe();
    });
  }

  /**
   * Handler for submitting story via the form
   */
  async onSubmit() {
    // TODO: Publish event from video form to update each time a video is upload
    // Update form before submission with selected videos
    this.storyForm.patchValue({
      videos: this.videos,
      user: this.currentSelectedUser
    });

    if (!this.storyForm.valid || !this.videos.length || !this.attraction) {
      // TODO: Decide if we want to show a dialog/error here with all errors listed
      this.hasOpenedAttraction = true;
      this.hasOpenedVideo = true;
      return;
    }

    const story: StoryModel = new StoryModel().parse(this.storyForm.getRawValue());
    console.log(`Raw story from form`, story);
    // story.attractionRef = await this.attractionService.getRefById(story.attractionId);
    this.submitStory.emit(story);
  }

  async onSelectAttraction(attraction: AttractionSearchModel) {
    this.hasOpenedAttraction = true;
    this.attraction = attraction;
    const attractionId = attraction.objectID;
    // const attractionRef = await this.attractionService.getRefById(attraction.objectID);

    this.storyForm.patchValue({
      attraction: new AttractionModel().parse(attraction),
      attractionId
      // attractionRef
    });
    // If the story doesn't have a name yet and the attraction does, set the story name
    if (attraction.hasOwnProperty('name') && !!attraction.name && (!this.story || !this.story.name)) {
      this.storyForm.patchValue({
        name: attraction.name
      });
    }
  }

  async setCurrentUser(userModel: UserModel|UserSearchModel) {
    this.currentSelectedUser = new UserModel().parse(userModel);
    // Search model doesn't have email returned so get full user model
    const userResult = await this.userService.findUserByUid(userModel.uid);
    this.currentSelectedUser.email = userResult.data.email;
    this.storyForm.patchValue({ user: this.currentSelectedUser });
  }

  // async setCurrentUserFromAuth(user: User) {
  //   const userResults = await this.userService.searchByObjectIDs([user.uid]);
  //   if (userResults && userResults.length) {
  //     this.currentSelectedUser = new UserModel().parse(userResults[0]);
  //     this.currentSelectedUser.email = user.email;
  //   }
  // }

  /**
   * Display function for user lookup autocomplete
   * @param user Firebase user
   * @return Formatted user name/email display
   */
  userSearchDisplayFn(user: UserSearchModel): string {
    return !user ? undefined : user.displayName ? `${user.displayName} <${user.email ? user.email : 'loading...'}>` : 'Display name unavailable';
  }

  // TODO: Move to a common utility file
  /**
   * Generic error handle for form fields
   * @param fieldName Form group field name
   * @param errorName Validation error name
   */
  hasError(fieldName: string, errorName: string): boolean {
    const control = this.storyForm.controls[fieldName];
    return control.touched && control.hasError(errorName);
  }
}
