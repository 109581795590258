import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private isLoggedIn: boolean;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    // Set an initial state if the user is logged
    this.isLoggedIn = !!this.authService.getUser();
    // Monitor changes in auth
    this.authService.getAuthState().subscribe((user) => {
      this.isLoggedIn = !!user;
      console.log(`User is logged in: ${this.isLoggedIn}`);
      // Redirect to home if user is logged in
      if (this.isLoggedIn) {
        this.goToAdminHome();
      }
    });
  }

  ngOnInit() {

  }

  loginWithGoogle() {
    // TODO: Handle login failure from google
    this.authService.loginWithGoogle()
      .then((res) => {
        console.log(`Login to google response: `, res);
        this.goToAdminHome();
      })
      .catch((err) => {
        console.log(`Failed to login with Google`, err);
      });
  }

  goToAdminHome() {
    this.router.navigate(['/admin']);
  }
}
