import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as algoliasearch from 'algoliasearch';
import { StoryModel } from './story.model';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { stringify } from '@angular/compiler/src/util';
import { environment } from '../../environments/environment';
import { JsonMapUtil } from '../shared/json-map.util';
import { CloudMediaModel } from '../shared/cloud-media.model';
import { AttractionService } from '../attraction/attraction.service';

@Injectable({
  providedIn: 'root'
})
export class StoryService {
  public readonly collectionName = 'stories';
  private storyCollection: AngularFirestoreCollection<any>;
  private client: algoliasearch.Client;
  private storyIndex: algoliasearch.Index;

  constructor(
    private afs: AngularFirestore,
    private attractionService: AttractionService
  ) {
    this.storyCollection = this.afs.collection(this.collectionName);
    this.client = algoliasearch(environment.algolia.appId, environment.algolia.searchApiKey);
    this.storyIndex = this.client.initIndex(environment.algolia.storyIndexName);
  }

  /**
   * Add a new story to Firestore
   * @param story New story to be added
   */
  add(story: StoryModel) {
    // Set the default timestamps
    story.createdAt = new Date();
    story.updatedAt = new Date();

    // Add through admin to make approved
    story.adminAction = 'approved';

    // Ensure user UID is set from the user object
    story.userUid = story.user.uid;

    // Generate and story the video URL for the app
    story.appVideoUrl = this.getConcatenatedVideo(story.videos);

    return this.storyCollection.add(story.toRawObject());
  }

  /**
   * Update a story to Firestore
   * @param story New story to be added
   */
  update(storyUid: string, story: StoryModel) {
    // Set the default timestamps
    story.updatedAt = new Date();

    // Ensure user UID is set from the user object
    story.userUid = story.user.uid;

    // Generate and story the video URL for the app
    story.appVideoUrl = this.getConcatenatedVideo(story.videos);

    return this.storyCollection.doc(storyUid).set(story.toRawObject(), { merge: true });
  }

  /**
   * Get a single story document by the document UID
   * Stories coming from app don't have attractionId explicitly set on story object
   * so there is a check to add it if necessary. (This has been fixed in the app, but there
   * may be some leftovers missing this field).
   * @param storyUid Story document UID
   */
  getById(storyUid: string): Observable<any> {
    return this.storyCollection.doc(storyUid).get().pipe(
      mergeMap(async (storyDoc) => {
        const story = new StoryModel().parse(storyDoc.data());
        if (!story.attractionId) {
          story.attractionId = await this.attractionService.getByPlaceId(story.attraction.place.placeId);
        }
        return story;
      })
    );
  }

  getByUser(userUid: string) {
    return this.afs
      .collection<StoryModel>(this.collectionName, ref => ref.where('userUid', '==', userUid))
      .valueChanges();
  }

  async getByUserAsync(userUid: string): Promise<Map<string, StoryModel>> {
    console.log(`Enter getByUser for ${userUid}`);
    const storyMap = new Map<string, StoryModel>();
    return new Promise<Map<string, StoryModel>>((resolve, reject) => {
      this.afs
        .collection(this.collectionName, ref => ref.where('userUid', '==', userUid)).snapshotChanges().subscribe((snapshots) => {
          this.afs
            .collection<StoryModel>(this.collectionName, ref => ref.where('userUid', '==', userUid))
            .valueChanges().subscribe((values) => {
              for (let i = 0; i < values.length; ++i) {
                storyMap.set(snapshots[i].payload.doc.id, values[i]);
              }
              resolve(storyMap);
            });
        });
    });
  }

  /**
   * Get all stories that require approval.
   */
  getForApproval() {
    return this.afs
      .collection<StoryModel>(this.collectionName, ref => ref
        .where('adminAction', '==', null)
        .where('published', '==', false))
      .snapshotChanges()
      .pipe(
        map((values) => {
          return values.map(s => {
            const story = new StoryModel().parse(s.payload.doc.data());
            story.videos = s.payload.doc.data().videos.map(v => new CloudMediaModel().parse(v));
            story.id = s.payload.doc.id;
            return story;
          });
        }),
        take(1)
      );
  }

  /**
   * update approval status of a story
   * @param action - name of action (approved or rejected)
   * @param storyUid - Uid of story to update
   */
  updateApproval(action: string, storyUid: string) {
    return this.afs.collection(this.collectionName).doc(storyUid).set({
      adminAction: action,
      published: action === 'approved'
    }, { merge: true });
  }

  async search(query: string): Promise<StoryModel[]> {
    const queryParams: algoliasearch.QueryParameters = {
      query,
      hitsPerPage: 100
    };
    return this.storyIndex.search(queryParams).then((res) => {
      return res.hits;
    });
  }

  /**
   * Combines all videos into a single video
   * @param videos List of video responses from Cloudinary
   */
  getConcatenatedVideo(videos: CloudMediaModel[]): string {
    const urlSplits = videos[0].secureUrl.split(/https:\/\/res\.cloudinary\.com\/navi-savi\/video\/upload\//);

    // Base video URL with environment
    let urlParts = [
      'https://res.cloudinary.com/',
      environment.cloudinary.cloudName,
      '/video/upload/'
    ];

    let maxHeight = 0;
    let maxWidth = 0;

    // Find the largest video and use it's aspect ratio
    // Fixes the issue with transforming videos with different aspect ratios
    videos.forEach((video) => {
      if (video.height > maxHeight) {
        maxHeight = video.height;
        maxWidth = video.width;
      }
    });

    const aspectRatio = 'w_' + maxWidth + ',h_' + maxHeight;

    // Push the transformation for the first video
    urlParts.push(aspectRatio + ',c_fill/');

    // Add to end (fl_splice) each video with the cover fill
    videos.forEach((video) => {
      // Skip first video, will be the base video at the end of the URL
      if (video == videos[0]) { return; }
      // Public ID is identifer for linked video (l_video)
      // Not documented but it must have the "/" replaced with ":"
      urlParts.push('l_video:' +
        video.publicId.replace(/\//g, ':') +
        ',' + aspectRatio +
        ',fl_splice,c_fill/');
    });

    // Add the first video as the base video
    urlParts.push(urlSplits[1]);

    let videoUrl = urlParts.join('');

    // Force generated video to use mp4
    videoUrl = videoUrl.substring(0, videoUrl.lastIndexOf('.')) + '.mp4';

    console.log('Concatenated video URL: ' + videoUrl);

    return videoUrl;
  }
}
