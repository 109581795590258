import { JsonProperty } from '../shared/json-map.util';
import { BaseModel } from '../shared/base.model';

class PlaceAddressComponent extends BaseModel {
  longName: string = undefined;
  shortName: string = undefined ;
  types: string[] = [];

  parse(json?: Object) {
    super.parse(json);
    this.longName = this.longName || json['long_name'];
    this.shortName = this.longName || json['short_name'];
    return this;
  }
}

/**
 * Simplified version for storage of `google.maps.places.PlaceResponse`
 */
export class AttractionPlaceModel extends BaseModel {
  @JsonProperty({ clazz: PlaceAddressComponent })
  addressComponents: PlaceAddressComponent[] = [];
  formattedAddress: string = undefined;
  name: string = undefined;
  placeId: string = undefined;
  types: string[] = [];
  url: string = undefined;
  vicinity: string = undefined;

  parse(json?: Object) {
    super.parse(json);
    // TODO: Automatically normalize incoming json properties to camelCase
    this.addressComponents = this.addressComponents ||
        json['address_components'].map((value) => new PlaceAddressComponent().parse(value));
    this.formattedAddress = json['formatted_address'];
    this.placeId = json['place_id'];

    return this;
  }

  toRawObject() {
    const obj = super.toRawObject();
    obj['addressComponents'] = this.addressComponents.map((value) => value.toRawObject());
    return obj;
  }
}

export class AttractionModel extends BaseModel {
  name: string = undefined;
  url: string = undefined;
  description: string = undefined;
  @JsonProperty({ clazz: AttractionPlaceModel })
  place: AttractionPlaceModel = undefined;
  address: string = undefined;
  lat: number = undefined;
  lon: number = undefined;
  tags: string[] = [];
  price: string = undefined;
  createdAt: Date = undefined;
  updatedAt: Date = undefined;

  toRawObject() {
    const obj = super.toRawObject();
    obj['place'] = this.place.toRawObject();
    return obj;
  }
}

export class AttractionSearchModel extends AttractionModel {
  objectID: string = undefined;
}
