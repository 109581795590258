import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Location as GMALocation } from '@angular-material-extensions/google-maps-autocomplete';
import { AttractionModel, AttractionPlaceModel } from '../attraction.model';

@Component({
  selector: 'app-attraction-form',
  templateUrl: './attraction-form.component.html',
  styleUrls: ['./attraction-form.component.scss']
})
export class AttractionFormComponent implements OnInit {
  @Output() submitAttraction: EventEmitter<AttractionModel> = new EventEmitter();
  @Input() attraction: any;

  tagList = ['Eat', 'Sleep', 'Play', 'Relax', 'Explore'];
  priceList = ['', 'Free', '$', '$$', '$$$', '$$$$', '$$$$$'];
  urlRegex: RegExp = new RegExp(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/);

  attractionForm = this.fb.group({
    name: [null, Validators.required],
    description: [null],
    url: [null, Validators.pattern(this.urlRegex)],
    address: [null, Validators.required],
    place: [null, Validators.required],
    lat: [null, Validators.required],
    lon: [null, Validators.required],
    tags: null,
    price: null
  });

  constructor(
    private fb: FormBuilder
  ) {

  }

  ngOnInit() {
    if (this.attraction) {
      console.log(`Attraction passed in, patching values`, this.attraction);
      this.attractionForm.patchValue(this.attraction);
    }
  }

  /**
   * Handler for submitting attraction via the form
   */
  onSubmit() {
    if (this.attractionForm.valid) {
      const attraction = new AttractionModel().parse(this.attractionForm.getRawValue());
      console.log(`Parsed attraction from form`, attraction);
      this.submitAttraction.emit(attraction);
    } else {
      this.attractionForm.controls.name.markAsTouched();
      this.attractionForm.controls.address.markAsTouched();
    }
  }

  /**
   * Handler for autocomplete selected for Google Places lookup
   * @param result Google Places result
   */
  onAutocompleteSelected(result: google.maps.places.PlaceResult) {
    console.log('onAutocompleteSelected: ', result);
    this.attractionForm.patchValue({
      place: new AttractionPlaceModel().parse(result),
      address: result.formatted_address
    });

    if (result.name) {
      this.attractionForm.patchValue({
        name: result.name
      });
    }
  }

  /**
   * Handler for location selected for Google Places lookup
   * @param location Latitude & logitude from Google Places
   */
  onLocationSelected(location: GMALocation) {
    console.log('onLocationSelected: ', location);
    this.attractionForm.patchValue({
      lat: location.latitude,
      lon: location.longitude
    });
  }

  // TODO: Move to a common utility file
  /**
   * Generic error handle for form fields
   * @param fieldName Form group field name
   * @param errorName Validation error name
   */
  hasError(fieldName: string, errorName: string): boolean {
    const control = this.attractionForm.controls[fieldName];
    return control.touched && control.hasError(errorName);
  }
}
