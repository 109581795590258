import { Routes } from '@angular/router';
import { StoryComponent } from './story.component';
import { StoryFormComponent } from './story-form/story-form.component';
import { StoryEditComponent } from './story-edit/story-edit.component';
import { StoryAddComponent } from './story-add/story-add.component';
import { StoryManagementComponent } from './story-management/story-management.component';
import { AuthGuard } from '../auth/auth.guard';


export const storyRoutes: Routes = [{
  path: 'story',
  component: StoryComponent,
  children: [
    // TODO: Remove this testing path when add/edit are completed
    { path: 'form', component: StoryFormComponent, canActivate: [AuthGuard] },
    { path: 'add', component: StoryAddComponent, canActivate: [AuthGuard] },
    { path: 'edit/:id', component: StoryEditComponent, canActivate: [AuthGuard] },
    { path: 'management', component: StoryManagementComponent, canActivate: [AuthGuard] },
  ]
}];
