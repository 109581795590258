import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { User } from 'firebase';
import { Observable, of } from 'rxjs';
import { take, tap, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authenticated: boolean;
  user: Observable<User>;

  private currentUser: User;

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public router: Router
  ) {

    // Monitor the authentication state
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          this.authenticated = true;
          // Set and save the user
          this.currentUser = user;
          console.log('User in authState: ', user);
          localStorage.setItem('user', JSON.stringify(this.currentUser));
          // Could pull additional data here from Firestore if needed
          // return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
          return of(user);
        } else {
          // Initial not logged in state or reset user on signout
          this.authenticated = false;
          this.currentUser = null;
          localStorage.setItem('user', null);
          return of(null);
        }
      })
    );
  }

  /**
   * Executes login with Google and returns Promise
   * @return User credentials from login
   */
  loginWithGoogle(): Promise<auth.UserCredential> {
    const provider = new auth.GoogleAuthProvider();
    // TODO Review scopes for Google OAuth
    provider.addScope('profile');
    provider.addScope('email');
    return this.afAuth.auth
      .signInWithPopup(provider);
  }

  /**
   * Executes login with Facebook and returns Promise
   * @return User credentials from login
   */
  loginWithFacebook(): Promise<auth.UserCredential> {
    const provider = new auth.FacebookAuthProvider();
    // TODO Review scopes for Facebook OAuth
    return this.oAuthLogin(provider);
  }

  /**
   * Exposes the auth state for other components to subscribe
   */
  getAuthState(): Observable<User> {
    return this.afAuth.authState;
  }

  /**
   * Used for initial auth service state of the user only
   * Use the observable instead, here for debugging
   *
   * @returns Authenticated user, null if not authenticated
   */
  getUser(): User {
    return this.currentUser;
  }

  /**
   * Sign user completely out of Firebase
   */
  signOut(): Promise<void> {
    return this.afAuth.auth.signOut().then(() => {
      console.log('User signed out');
      // this.router.navigate(['/login']);
    });
  }

  /**
   * Generic OAuth function to sign in with a popup
   * @param provider Firebase auth provider
   */
  private oAuthLogin(provider: auth.AuthProvider): Promise<auth.UserCredential> {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        this.updateUserData(credential.user);
        return credential;
      });
  }

  /**
   * Sets user data to firestore on login
   * @param user Firebase user
   */
  private updateUserData(user: User) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    return userRef.set(
      {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
      },
      { merge: true }
    );
  }
}
