import { Component, OnInit } from '@angular/core';
import { StoryModel } from '../story.model';
import { StoryService } from '../story.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { MatSnackBar, MatDialog } from '@angular/material';
import { UserService } from '../../shared/user.service';
import { StoryVideoDialogComponent } from '../story-video-dialog/story-video-dialog.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-story-edit',
  templateUrl: './story-edit.component.html',
  styleUrls: ['./story-edit.component.scss']
})
export class StoryEditComponent implements OnInit {

  story: StoryModel;
  storyUid: string;
  loading = true;
  errorMessage = 'Unknown error occurred.';
  fromApprovalQueue: boolean;

  constructor(
    private storyService: StoryService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private location: Location
  ) {

  }

  ngOnInit() {
    this.route.params
      .pipe(
        catchError((err, caught) => {
          console.log(`Failed to load story: ${err}`, err);
          return caught;
        })
      )
      .subscribe((params) => {
        console.log(`Edit story parameters`, params);
        if (params.hasOwnProperty('fromQueue')) {
          this.fromApprovalQueue = (params.fromQueue === 'true');
        }
        if (params.hasOwnProperty('id') && params.id) {
          console.log(`Found story id parameter ${params.id}`);
          // Save the id since it won't come back with the document in @angular/fire
          this.storyUid = params.id;
          // Get the requested story
          this.storyService.getById(params.id)
            .pipe(
              catchError((err, caught) => {
                console.warn(`Failed to load story`, err);
                this.loading = false;
                this.errorMessage = `${err}`;
                // Pass along caught Observable to fail gracefully
                return caught;
              })
            )
            .subscribe((storyModel) => {
              console.log(`Found story`, storyModel);
              this.story = new StoryModel().parse(storyModel);
              this.loading = false;
            });
        } else {
          // This should almost never happen as route should be rejected but it's a safety catch
          this.errorMessage = 'Unable to get the UID parameter of story to edit.';
          this.loading = false;
        }
      });
  }

  onSubmitStory(story: StoryModel) {
    console.log(`Updating story: `, story);
    this.storyService.update(this.storyUid, story)
      .then((savedStory) => {
        this.snackBar.open('Story successfully saved.', null, {
          duration: 5000
        });
        this.dialog.open(StoryVideoDialogComponent, {
          data: { story: story }
        }).afterClosed().subscribe(() => {
          if (this.fromApprovalQueue) {
            this.location.back();
          } else {
            this.router.navigate(['admin', 'story', 'management']);
          }
        });
      })
      .catch((err) => {
        console.log(`Failed to add story`, err);
        this.snackBar.open(`Failed to add story "${err}"`);
      });
  }

}
