import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AttractionModel, AttractionSearchModel } from './attraction.model';
import { map } from 'rxjs/operators';
import * as algoliasearch from 'algoliasearch';
import { environment } from '../../environments/environment';
import { JsonMapUtil } from '../shared/json-map.util';
import { unescapeIdentifier } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class AttractionService {
  public readonly collectionName = 'attractions';
  private attractionCollection: AngularFirestoreCollection<any>;
  private client: algoliasearch.Client;
  private attractionIndex: algoliasearch.Index;

  constructor(
    private afs: AngularFirestore
  ) {
    this.attractionCollection = this.afs.collection(this.collectionName);
    this.client = algoliasearch(environment.algolia.appId, environment.algolia.searchApiKey);
    this.attractionIndex = this.client.initIndex(environment.algolia.attractionIndexName);
  }

  /**
   * Adds a new document to the collection
   * @param attraction New attraction to be added (id should not be set)
   */
  add(attraction: AttractionModel) {
    // Set the default timestamps
    attraction.createdAt = new Date();
    attraction.updatedAt = new Date();


    console.log('Saveable Attraction', attraction.toRawObject());

    // return this.collection.add(JSON.parse(JSON.stringify(attraction)));
    return this.collection.add(attraction.toRawObject());
  }


  /**
   * Updates an existing document in the collection
   * @param attractionId Id of the attraction (`attraction.id` is not used)
   * @param attraction Updated attraction document
   */
  update(attractionId: string, attraction: AttractionModel) {
    // Set the default timestamps
    attraction.updatedAt = new Date();

    return this.collection.doc(attractionId).set(attraction.toRawObject(), { merge: true });
  }

  getById(attractionId: string) {
    return this.collection.doc(attractionId).get().pipe(
      map((attractionDoc) => {
        return attractionDoc.data();
      })
    );
  }

  getRefById(attractionId: string): DocumentReference {
    return this.collection.doc(attractionId).ref;
  }

  async search(query: string): Promise<AttractionSearchModel[]> {

    const queryParams: algoliasearch.QueryParameters = {
      query: query,
      hitsPerPage: 100
    };
    return this.attractionIndex.search(queryParams).then((res: algoliasearch.Response) => {
      return res.hits.map((value) => new AttractionSearchModel().parse(value));
    });

    // return Observable.create((observer: Observer) => {
    //   observer.next(res.hits);
    //   observer.complete();
    // });
  }

  /**
   * Get an attraction by placeId
   * @param placeId - id of google place associated with attraction
   */
  async getByPlaceId(placeId: string): Promise<string> {
     const attraction = await this.collection.ref.where('place.placeId', '==', placeId)
      .get();

     if (attraction.docs[0]) {
       return attraction.docs[0].id;
     }
     return '';
  }

  // Do not use
  private findByName(name: string) {
    return this.collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
    }));
  }

  /**
   * Default collection for Attraction service
   */
  get collection(): AngularFirestoreCollection<any> {
    return this.attractionCollection;
  }
}
