import { BaseModel } from './base.model';
declare var Reflect: any;

export function getClazz(target: any, propertyKey?: string): any {
  if (propertyKey) {
    return Reflect.getMetadata("design:type", target, propertyKey);
  }
  return Reflect.getMetadata("design:type", target);
}

export function getJsonProperty<T>(target: any, propertyKey: string):  IJsonMetaData<T> {
  return Reflect.getMetadata(jsonMetadataKey, target, propertyKey);
}

export interface IJsonMetaData<T> {
  name?: string,
  clazz?: {new(): T}
}

const jsonMetadataKey = "jsonProperty";

export function JsonProperty<T>(metadata?:IJsonMetaData<T>|string): any {
  if (metadata instanceof String || typeof metadata === "string"){
    return Reflect.metadata(jsonMetadataKey, {
      name: metadata,
      clazz: undefined
    });
  } else {
    let metadataObj = <IJsonMetaData<T>>metadata;
    return Reflect.metadata(jsonMetadataKey, {
      name: metadataObj ? metadataObj.name : undefined,
      clazz: metadataObj ? metadataObj.clazz : undefined
    });
  }
}

export class JsonMapUtil {
  static isPrimitive(obj) {
    switch (typeof obj) {
      case "string":
      case "number":
      case "boolean":
        return true;
    }
    return !!(obj instanceof String || obj === String ||
    obj instanceof Number || obj === Number ||
    obj instanceof Boolean || obj === Boolean);
  }

  static isArray(object) {
    if (object === Array) {
      return true;
    } else if (typeof Array.isArray === "function") {
      return Array.isArray(object);
    }
    else {
      return !!(object instanceof Array);
    }
  }

  static deserialize<T>(clazz:{new(): T}, jsonObject, target?: T) {
    if (clazz && !target) {
        target = new clazz();
    }

    if ((target === undefined) || (jsonObject === undefined)) return undefined;

    Object.keys(target).forEach((key) => {
      let propertyMetadataFn:(IJsonMetaData) => any = (propertyMetadata)=> {
        let propertyName = propertyMetadata.name || key;
        let innerJson = jsonObject ? jsonObject[propertyName] : undefined;
        let innerClazz = getClazz(target, key);
        // Handle case when inner class is an array
        if (JsonMapUtil.isArray(innerClazz)) {
          let metadata = getJsonProperty(target, key);

          if (metadata.clazz || JsonMapUtil.isPrimitive(innerClazz)) {

            if (innerJson && JsonMapUtil.isArray(innerJson)) {
              return innerJson.map(
                (item) => JsonMapUtil.deserialize(metadata.clazz, item)
              );
            } else {
              return undefined;
            }

          } else {
            return innerJson;
          }
        } else if (!JsonMapUtil.isPrimitive(innerClazz)) {
          return JsonMapUtil.deserialize(innerClazz, innerJson);
        } else {
          return jsonObject ? jsonObject[propertyName] : undefined;
        }
      };

      let propertyMetadata = getJsonProperty(target, key);
      if (propertyMetadata) {
        target[key] = propertyMetadataFn(propertyMetadata);
      } else {
        if (jsonObject && jsonObject[key] !== undefined) {
          target[key] = jsonObject[key];
        }
      }
    });
    return target;
  }

  static toJSON(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }
}
