import { BaseModel } from "./base.model";
import { JsonProperty } from './json-map.util';

///
/// Mapping classes for uploaded Cloudinary media (audio/video)
///

export class CloudMediaAudioModel extends BaseModel {
  code: string = undefined;
  bitRate: string = undefined;
  frequency: number = undefined;
  channels: number = undefined;
  channelLayout: string = undefined;

  parse(json?: Object) {
    super.parse(json);
    this.bitRate = this.bitRate || json['bit_rate'];
    this.channelLayout = this.channelLayout || json['channel_layout'];
    return this;
  }
}

export class CloudMediaVideoModel extends BaseModel {
  bitRate: string = undefined;
  codec: string = undefined;
  dar: string = undefined;
  level: number = undefined;
  pixFormat: string = undefined;
  profile: string = undefined;

  parse(json?: Object) {
    super.parse(json);
    this.bitRate = this.bitRate || json['bit_rate'];
    this.pixFormat = this.pixFormat || json['pix_format'];
    return this;
  }
}

export class CloudMediaModel extends BaseModel {
  accessMode: string = undefined;
  @JsonProperty({ clazz: CloudMediaAudioModel })
  audio: CloudMediaAudioModel = undefined;
  bitRate: number = undefined;
  bytes: number = undefined;
  createdAt: Date = undefined;
  deleteToken: string = undefined;
  duration: number = undefined;
  etag: string = undefined;
  format: string = undefined;
  frameRate: number = undefined;
  height: number = undefined;
  isAudio: boolean = undefined;
  originalFilename: string = undefined;
  pages: number = undefined;
  placeholder: boolean = undefined;
  publicId: string = undefined;
  resourceType: string = undefined;
  rotation: number = undefined;
  secureUrl: string = undefined;
  signature: string = undefined;
  tags: string[] = [];
  type: string = undefined;
  url: string = undefined;
  version: number = undefined;
  @JsonProperty({ clazz: CloudMediaVideoModel })
  video: CloudMediaVideoModel = undefined;
  width: number = undefined;

  parse(json?: Object) {
    super.parse(json);
    this.bitRate = this.bitRate || json['bit_rate'];
    this.createdAt = this.createdAt || json['created_at'];
    this.deleteToken = this.deleteToken || json['delete_token'];
    this.accessMode = this.accessMode || json['access_mode'];
    this.frameRate = this.frameRate || json['frame_rate'];
    this.isAudio = this.isAudio || json['is_audio'];
    this.originalFilename = this.originalFilename || json['original_filename'];
    this.publicId = this.publicId || json['public_id'];
    this.resourceType = this.resourceType || json['resource_type'];
    this.secureUrl = this.secureUrl || json['secure_url'];
    return this;
  }

  toRawObject() {
    const obj = super.toRawObject();
    obj['audio'] = this.audio.toRawObject();
    obj['video'] = this.video.toRawObject();
    return obj;
  }

  get videoPreviewImageUrl(): string {
    return this.secureUrl.substring(0, this.secureUrl.lastIndexOf('.')) + '.jpg';
  }

  get firstFrameImageUrl(): string {
    const urlSplits = this.secureUrl.split(/https:\/\/res\.cloudinary\.com\/navi-savi\/video\/upload\//);
    let videoUrl =
        'https://res.cloudinary.com/navi-savi/video/upload/so_0.0/';

    videoUrl += urlSplits[1];

    return videoUrl.substring(0, videoUrl.lastIndexOf('.')) + '.jpg';
  }
}
