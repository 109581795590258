import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AttractionService } from '../attraction.service';
import { AttractionModel } from '../attraction.model';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { matSnackBarAnimations } from '@angular/material';

@Component({
  selector: 'app-attraction-add',
  templateUrl: './attraction-add.component.html',
  styleUrls: ['./attraction-add.component.scss']
})
export class AttractionAddComponent implements OnInit, OnDestroy {
  @Output() addAttraction: EventEmitter<{id: string, model: AttractionModel}> = new EventEmitter();
  addAttractionSubscription: Subscription = new Subscription();
  fromMgmt = false;

  constructor(
    private attractionService: AttractionService,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit() {
    this.addAttractionSubscription.add(this.route.queryParams.subscribe((params) => {
      this.fromMgmt = params.fromMgmt && params.fromMgmt === 'true';
    }));
  }

  ngOnDestroy(): void {
    this.addAttractionSubscription.unsubscribe();
  }

  /**
   * Handler for attraction submission
   * This will also emit on successfully adding to the `addAttraction` event emitter
   * @param attraction Attraction to be added
   */
  onSubmitAttraction(attraction: AttractionModel) {
    console.log('Attraction before add', attraction);
    this.attractionService.add(attraction).then((result) => {
      console.log('AttractionAddDialogComponent.onSubmitAttraction', result);
      this.addAttraction.emit({ id: result.id, model: attraction });
      if (this.fromMgmt) {
        this.location.back();
        alert('Attraction Added!');
      }
    });
  }
}
