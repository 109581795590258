import { JsonMapUtil } from './json-map.util';

function avoidCircularReference(obj) {
  return function(key, value) {
    return key && typeof value === 'object' && obj === value ? undefined : value;
  };
}

export class BaseModel extends Object {

  parse(json?: Object) {
    if (json) {
      JsonMapUtil.deserialize(null, json, this);
    }
    return this;
  }

  toRawObject(): Object {
    const obj = { ...this };
    for (const prop in obj) {
      // Firebase will reject undefined values
      if (obj[prop] === undefined) {
        delete(obj[prop]);
      }
    }
    return obj;
  }
}
