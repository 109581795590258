import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../shared/user.service';

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.scss']
})
export class SuperAdminComponent implements OnInit {

  addAdminForm = this.fb.group({
    email: [null, Validators.required]
  });

  constructor(
    private aff: AngularFireFunctions,
    private fb: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  resetAttractionIndex() {
    const callable = this.aff.functions.httpsCallable('resetAttractionIndex');
    return callable();
  }

  resetStoryIndex() {
    const callable = this.aff.functions.httpsCallable('resetStoryIndex');
    return callable();
  }

  resetSavedStoryIndex() {
    const callable = this.aff.functions.httpsCallable('resetSavedStoryIndex');
    return callable();
  }

  resetUserIndex() {
    const callable = this.aff.functions.httpsCallable('resetUserIndex');
    return callable();
  }

  syncUsers() {
    const callable = this.aff.functions.httpsCallable('syncUsers');
    return callable();
  }

  addVerifiedField() {
    const callable = this.aff.functions.httpsCallable('addVerifiedField');
    return callable();
  }

  addDescriptionField() {
    const callable = this.aff.functions.httpsCallable('addAttractionDescriptionField');
    return callable();
  }

  async addAdmin() {
    if (this.addAdminForm.invalid) {
      return;
    }

    const email = this.addAdminForm.get('email').value;
    const response = await this.userService.findUserByEmail(email);

    if (!response && !response.data) {
      console.log('User is not found');
      return;
    }

    const userData = response.data;

    if (userData['customClaims'] && userData['customClaims']['admin']) {
      console.log('User is already an admin.');
      return;
    }

    const callable = this.aff.functions.httpsCallable('addAdmin');
    const result = await callable({ email: email });
    console.log('User is now an admin', result);
    return Promise.resolve();
  }

  // TODO: Move to a common utility file
  /**
   * Generic error handle for form fields
   * @param fieldName Form group field name
   * @param errorName Validation error name
   */
  hasAddAdminError(fieldName: string, errorName: string): boolean {
    const control = this.addAdminForm.controls[fieldName];
    return control.touched && control.hasError(errorName);
  }
}
