import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AttractionService } from '../attraction.service';
import { AttractionModel } from '../attraction.model';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-attraction-edit',
  templateUrl: './attraction-edit.component.html',
  styleUrls: ['./attraction-edit.component.scss']
})
export class AttractionEditComponent implements OnInit {
  @Output() editAttraction: EventEmitter<{id: string, model: AttractionModel}> = new EventEmitter();
  editFromStoryForm = false;
  attraction: AttractionModel;
  attractionId: string;
  loading = true;
  errorMessage: string;

  constructor(
    private attractionService: AttractionService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.route.params
      .pipe(
        catchError((err, caught) => {
          console.log(`Failed to load attraction: ${err}`, err);
          return caught;
        })
      )
      .subscribe((params) => {
        console.log(`Edit attraction parameters`, params);
        if (params.hasOwnProperty('id') && params.id) {
          console.log(`Found attraction id parameter ${params.id}`);
          // Save the id since it won't come back with the document in @angular/fire
          this.attractionId = params.id;
          // Get the requested attraction
          this.attractionService.getById(params.id)
            .pipe(
              catchError((err, caught) => {
                console.warn(`Failed to load attraction`, err);
                this.loading = false;
                this.errorMessage = `${err}`;
                // Pass along caught Observable to fail gracefully
                return caught;
              })
            )
            .subscribe((attractionModel: AttractionModel) => {
              console.log(`Found attraction`, attractionModel);
              this.attraction = attractionModel;
              this.loading = false;
            });
        } else {
          // This should almost never happen as route should be rejected but it's a safety catch
          this.errorMessage = 'Unable to get the ID parameter of attraction to edit.';
          this.loading = false;
        }
      });
  }

  onSubmitAttraction(attraction: any) {
    console.log(`Updating attraction: `, attraction);
    this.attractionService.update(this.attractionId, attraction)
      .then((savedAttraction) => {
        if (this.editFromStoryForm) {
          this.editAttraction.emit({ id: this.attractionId, model: attraction })
        } else {
          this.snackBar.open('Attraction successfully saved.', null, {
            duration: 5000
          });
          this.router.navigate(['admin', 'attraction', 'management']);
        }
      })
      .catch((err) => {
        console.log(`Failed to add attraction`, err);
        this.snackBar.open(`Failed to add attraction "${err}"`);
      });
  }
}
