import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AttractionModel, AttractionSearchModel } from '../attraction.model';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AttractionService } from '../attraction.service';
import { MatAutocompleteSelectedEvent } from '@angular/material';

@Component({
  selector: 'app-attraction-search',
  templateUrl: './attraction-search.component.html',
  styleUrls: ['./attraction-search.component.scss']
})
export class AttractionSearchComponent implements OnInit, OnChanges {
  @Output() selectAttraction: EventEmitter<AttractionSearchModel> = new EventEmitter();
  @Input() attraction: AttractionSearchModel;

  attractionSearchForm = this.fb.group({
    attraction: null
  });

  attractionSearchSubscription: Subscription;
  attractionSearchResults: AttractionSearchModel[];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private attractionService: AttractionService
  ) { }

  ngOnInit() {
    this.attractionSearchSubscription = this.attractionSearchForm.controls.attraction.valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value) => {
      console.log('Attraction search value: ', value);
      this.attractionService.search(value).then((res) => {
        console.log('Attraction search result: ', res);
        if (res) {
          this.attractionSearchResults = res;
        }
      });
    });

    if (this.attraction) {
      this.attractionSearchForm.patchValue({
        attraction: this.attraction
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.attraction) {
      this.attractionSearchForm.patchValue({
        attraction: changes.attraction.currentValue
      });
    }
  }

  goToAddAttraction() {
    this.router.navigate(['attraction', 'add']);
  }

  attractionSearchDisplayFn(attraction: AttractionModel): string {
    return !attraction ? undefined : attraction.name ? `${attraction.name} <${attraction.address}>` : 'Display name unavailable';
  }

  onAttractionSelected(event: MatAutocompleteSelectedEvent) {
    console.log(`Attraction selected event`, event);
    this.selectAttraction.emit(event.option.value);
  }
}
