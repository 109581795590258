import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-user-signup',
  templateUrl: './user-signup.component.html',
  styleUrls: ['./user-signup.component.scss']
})
export class UserSignupComponent implements OnInit {
  isLoggedIn: boolean;

  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {
    this.isLoggedIn = !!this.authService.getUser();
    this.authService.getAuthState().subscribe((user) => {
      this.isLoggedIn = !!user;
      console.log(this.isLoggedIn);
    });
  }

  ngOnInit() {

  }

  loginWithGoogle() {
    this.authService.loginWithGoogle().then((res) => {
      console.log(res);
      // console.log(res.credential['accessToken']);
    }).catch((err) => {
      if (err.code === 'auth/account-exists-with-different-credential') {
        console.log(`already signed up with facebook: `, err);
        this.snackBar.open('Error: this email already signed up with Facebook');
      }
      });
  }

  loginWithFacebook() {
    this.authService.loginWithFacebook().then((res) => {
      console.log(res);
      // console.log(res.credential['accessToken']);
    }).catch((err) => {
      if (err.code === 'auth/account-exists-with-different-credential') {
        console.log(`already signed up with google: `, err);
        this.snackBar.open('Error: this email already signed up with Google');
      }
    });
  }

  signOut() {
    this.authService.signOut();
  }
}
