import { Component, OnInit } from '@angular/core';
import { StoryService } from '../story.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { StoryVideoDialogComponent } from '../story-video-dialog/story-video-dialog.component';
import { StoryModel } from '../story.model';
import { UserService } from './../../shared/user.service';
import { User } from 'firebase';
import { UserModel } from 'src/app/shared/user.model';

@Component({
  selector: 'app-story-add',
  templateUrl: './story-add.component.html',
  styleUrls: ['./story-add.component.scss']
})
export class StoryAddComponent implements OnInit {
  private story: StoryModel;
  public userUid: string;
  public user: UserModel;
  public refreshing = false;

  constructor(
    private storyService: StoryService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userService: UserService
  ) {

  }

  ngOnInit() {
    // Get the userUid from the snapshot
    this.setUserUid(this.route.snapshot.queryParamMap.get('userUid'));

    this.refreshForm();
  }

  /**
   * Resets a boolean to redraw the form anew
   */
  refreshForm() {
    // This is a hack to get Angular to refresh when a query parameter changes
    this.route.queryParamMap.subscribe((paramMap) => {
      const refresh = paramMap.get('refresh');
      if (refresh) {
        this.refreshing = true;
        // Refresh the userUid from `Observable` and not the snapshot
        this.setUserUid(paramMap.get('userUid'));
      }
    });
  }

  /**
   * Set the `userUid` and attempt to retrieve the user
   *
   * @param userUid User
   */
  setUserUid(userUid) {
    this.userUid = userUid;
    if (userUid) {
      this.userService.searchByObjectIDs([this.userUid]).then((res) => {
        this.user = res[0];
      });
    }
  }

  onSubmitStory(story: StoryModel) {
    console.log(`Adding story: `, story);
    this.refreshing = false;
    this.storyService.add(story)
      .then(async (savedStory) => {
        const data = (await savedStory.get()).data();
        this.story = new StoryModel().parse(data);
        this.snackBar.open('Story successfully added.', null, {
          duration: 5000
        });
        const dialogRef = this.dialog.open(StoryVideoDialogComponent, {
          data: {
            story: this.story,
            showContinue: true
          }
        });
        dialogRef.componentInstance.onCloseContinue.subscribe(() => {
          this.handleContinue();
        });
        dialogRef.componentInstance.onCloseDone.subscribe(() => {
          this.router.navigate(['admin', 'story', 'management']);
        });
      })
      .catch((err) => {
        console.log(`Failed to add story`, err);
        this.snackBar.open(`Failed to add story "${err}"`);
      });
  }

  /**
   * Routes to the Add Story page with the `userUid` preset
   */
  handleContinue() {
    this.router.navigate(['admin', 'story', 'add'], {
      queryParams: {
        userUid: this.story.userUid,
        refresh: (new Date()).getTime()
      }
    });
  }
}
