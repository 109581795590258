import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class CustomFontawesomeModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    library.add(
      faCopy,
      faFacebook,
      faGoogle
    );
  }
}
