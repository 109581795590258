import { Component, OnInit, Input, NgZone, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { environment } from '../../../environments/environment';
import { User } from 'firebase';
import { CloudMediaModel } from '../../shared/cloud-media.model';

@Component({
  selector: 'app-story-video-upload',
  templateUrl: './story-video-upload.component.html',
  styleUrls: ['./story-video-upload.component.scss']
})
export class StoryVideoUploadComponent implements OnInit {

  @Input()
  responses: Array<{file: any, progress?: any, status?: any, data: any}>;

  @Input()
  media: CloudMediaModel[];

  @Input()
  user: User;

  hasBaseDropZoneOver = false;
  uploader: FileUploader;

  constructor(
    private zone: NgZone,
    private http: HttpClient
  ) {
    this.responses = this.responses ? this.responses : [];
    this.media = this.media ? this.media : [];


    // Create the file uploader, wire it to upload to your account
    const uploaderOptions: FileUploaderOptions = {
      url: `https://api.cloudinary.com/v1_1/${environment.cloudinary.cloudName}/upload`,
      // Upload files automatically upon addition to upload queue
      autoUpload: true,
      // Use xhrTransport in favor of iframeTransport
      isHTML5: true,
      // Calculate progress independently for each uploaded file
      removeAfterUpload: true,
      // XHR request headers
      headers: [
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ],
      allowedFileType: ['video']
    };
    this.uploader = new FileUploader(uploaderOptions);
  }

  ngOnInit(): void {
    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      // Add Cloudinary's unsigned upload preset to the upload form
      form.append('upload_preset', environment.cloudinary.uploadPreset);
      // Add built-in and custom tags for displaying the uploaded photo in the list
      const tags = 'story';

      // Upload to a custom folder
      // 'Auto-create folders' option must be enabled to create folders
      form.append('folder', `${environment.cloudinary.uploadFolder}/${this.user.uid}`);
      // Add custom tags
      form.append('tags', tags);
      // Add file to upload
      form.append('file', fileItem);

      // Use default "withCredentials" value for CORS requests
      fileItem.withCredentials = false;
      return { fileItem, form };
    };

    // Insert or update an entry in the responses array
    const upsertResponse = fileItem => {

      // Run the update in a custom zone since for some reason change detection isn't performed
      // as part of the XHR request to upload the files
      // Running in a custom zone forces change detection
      this.zone.run(() => {
        // Update an existing entry if it's upload hasn't completed yet

        // Find the id of an existing item
        const existingId = this.responses.reduce((prev, current, index) => {
          if (current.file.name === fileItem.file.name && !current.status) {
            return index;
          }
          return prev;
        }, -1);
        if (existingId > -1) {
          // Update existing item with new data
          console.log(fileItem);
          this.responses[existingId] = Object.assign(this.responses[existingId], fileItem);
          // this.media[existingId] = Object.assign(this.media[existingId], fileItem.data);

        } else {
          // Create new response
          console.log(fileItem);
          this.responses.push(fileItem);
          // this.media.push(fileItem.data);
        }
      });
    };

    // Update model on completion of uploading a file
    this.uploader.onCompleteItem = (item: any, response: string, status: number, headers: ParsedResponseHeaders) => {
      const responseObj = JSON.parse(response);
      const cloudMedia = new CloudMediaModel().parse(responseObj);
      console.log(responseObj, cloudMedia)
      this.media.push(cloudMedia);
      return  upsertResponse(
      {
        file: item.file,
        status,
        data: JSON.parse(response)
      });
    };

    // Update model on upload progress event
    this.uploader.onProgressItem = (fileItem: any, progress: any) =>
      upsertResponse(
        {
          file: fileItem.file,
          progress,
          data: {}
        }
      );
  }

  // Delete an uploaded video
  // Requires setting "Return delete token" to "Yes" in your upload preset configuration
  // See also https://support.cloudinary.com/hc/en-us/articles/202521132-How-to-delete-an-image-from-the-client-side-
  // TODO: can only delete from cloudinary for 10 mins after upload. Need to look into another solution.
  deleteVideo(data: any, index: number) {
    const url = `https://api.cloudinary.com/v1_1/${environment.cloudinary.cloudName}/delete_by_token`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' });
    const options = { headers };
    const body = {
      token: data.delete_token
    };
    this.http.post(url, body, options).subscribe(response  => {
      console.log(`Deleted image - ${data.public_id}`, response);
      // Remove deleted item for responses
      this.responses.splice(index, 1);
      this.media.splice(index, 1);
    }, error => {
      this.responses.splice(index, 1);
      this.media.splice(index, 1);
    });
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  getFileProperties(fileProperties: any) {
    // Transforms Javascript Object to an iterable to be used by *ngFor
    if (!fileProperties) {
      return null;
    }
    return Object.keys(fileProperties)
      .map((key) => ({ key, value: fileProperties[key] }));
  }
}
