import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AttractionModel } from '../attraction.model';
import { AttractionService } from '../attraction.service';

/**
 * Placeholder class if we want to have different styling for dialog form
 */
@Component({
  selector: 'app-attraction-add-dialog',
  templateUrl: './attraction-add-dialog.component.html',
  styleUrls: ['./attraction-add-dialog.component.scss']
})
export class AttractionAddDialogComponent implements OnInit {
  @Output() addAttraction: EventEmitter<any> = new EventEmitter();

  constructor(
    private attractionService: AttractionService
  ) { }

  ngOnInit() {
  }

  onSubmitAttraction(attraction: AttractionModel) {
    console.log('Attraction before add', attraction);
    this.attractionService.add(attraction).then((result) => {
      console.log('AttractionAddDialogComponent.onSubmitAttraction', result);
      this.addAttraction.emit(result);
    });
  }

}
