import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { UserModel, UserSearchModel } from './user.model';
import * as algoliasearch from 'algoliasearch';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private client: algoliasearch.Client;
  private userIndex: algoliasearch.Index;

  constructor(
    private aff: AngularFireFunctions
  ) {
    this.client = algoliasearch(environment.algolia.appId, environment.algolia.searchApiKey);
    this.userIndex = this.client.initIndex(environment.algolia.userIndexName);
  }

  async searchUsers(query: string): Promise<UserSearchModel[]> {
    const queryParams: algoliasearch.QueryParameters = {
      query,
      hitsPerPage: 10
    };
    return this.userIndex.search(queryParams).then((res: algoliasearch.Response) => {
      return res.hits.map((value) => new UserSearchModel().parse(value));
    });
  }

  async searchByObjectIDs(objectIDs: string[]): Promise<UserModel[]> {
    return this.userIndex.getObjects(objectIDs).then((res) => {
      const users: UserModel[] = [];
      res.results.forEach((result) => users.push(new UserSearchModel().parse(result)));
      return users;
    });
  }

  /**
   * Find user by email (must be exact)
   * @param email Exact user email
   */
  findUserByEmail(email: string) {
    const callable = this.aff.functions.httpsCallable('getUserByEmail');
    return callable({ email });
  }

  /**
   * Find user by `uid` (must be exact)
   * @param uid Exact user uid
   */
  findUserByUid(uid: string) {
    const callable = this.aff.functions.httpsCallable('getUserByUid');
    return callable({ uid });
  }
}
