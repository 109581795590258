import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { CloudinaryModule, CloudinaryConfiguration } from '@cloudinary/angular';
// import { Cloudinary } from 'cloudinary-core';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MarkdownModule } from 'ngx-markdown';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { CustomMaterialModule } from './custom-material/custom-material.module';
import { NavBarComponent } from './nav-bar/nav-bar.component';

import { StoryModule } from './story/story.module';

import { UserSignupComponent } from './user-signup/user-signup.component';
import { ClipboardModule } from 'ngx-clipboard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CustomFontawesomeModule } from './custom-fontawesome/custom-fontawesome.module';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { HomeMainComponent } from './home/home-main/home-main.component';
import { SuperAdminComponent } from './super-admin/super-admin.component';
import { StoryApprovalQueueComponent } from './story-approval-queue/story-approval-queue.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HomeMainComponent,
    NavBarComponent,
    UserSignupComponent,
    PrivacyComponent,
    TermsComponent,
    SuperAdminComponent,
    StoryApprovalQueueComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    BrowserAnimationsModule,
    // Import all used modules from Angular Material
    CustomMaterialModule,
    StoryModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google.apiKey
    }),
    MatGoogleMapsAutocompleteModule.forRoot(),
    ClipboardModule,
    FontAwesomeModule,
    CustomFontawesomeModule,
    MarkdownModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

}
