import { AttractionModel } from '../attraction/attraction.model';
import { BaseModel } from '../shared/base.model';
import { JsonProperty } from '../shared/json-map.util';
import { UserModel } from '../shared/user.model';
import { CloudMediaModel } from '../shared/cloud-media.model';
import { AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';

export class StoryModel extends BaseModel {
  id: string = undefined;
  @JsonProperty({ clazz: UserModel })
  user: UserModel  = undefined;
  userUid: string = undefined;
  name: string = undefined;
  videoUrls: string[] = [];
  appVideoUrl: string = undefined;
  attractionId: string = undefined;
  @JsonProperty({ clazz: AttractionModel })
  attraction: AttractionModel = undefined;
  createdAt: Date = undefined;
  updatedAt: Date = undefined;
  published: boolean = undefined;
  @JsonProperty({ clazz: CloudMediaModel })
  videos: CloudMediaModel[] = [];
  adminAction: string = null;
  // attractionRef: DocumentReference;

  toRawObject() {
    const obj = super.toRawObject();
    obj['user'] = this.user.toRawObject();
    obj['attraction'] = this.attraction.toRawObject();
    obj['videos'] = this.videos.map((value) => value.toRawObject());
    return obj;
  }
}
