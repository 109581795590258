import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StoryService } from '../story/story.service';
import { StoryModel } from '../story/story.model';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-story-approval-queue',
  templateUrl: './story-approval-queue.component.html',
  styleUrls: ['./story-approval-queue.component.scss']
})
export class StoryApprovalQueueComponent implements OnInit, OnDestroy {
  approvalSubscription: Subscription = new Subscription();
  storiesForApproval: StoryModel[] = [];
  currentStoryPage: StoryModel[] = [];
  offset = 0;
  limit = 5;

  constructor(
    private router: Router,
    private storyService: StoryService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.approvalSubscription.add(this.storyService.getForApproval()
      .subscribe((stories) => {
        console.log(`stories for approval: `, stories);
        this.storiesForApproval = stories;
        this.getCurrentPage();
      }));
  }

  ngOnDestroy(): void {
    this.approvalSubscription.unsubscribe();
  }

  navigateHome() {
    this.router.navigate(['admin']);
  }

  async performApprovalAction(event: any, story: StoryModel) {
    switch (event.value) {
      case 'approved': {
        await this.storyService.updateApproval('approved', story.id);
        this.snackBar.open('Story Approved!', );
        setTimeout(() => {
          this.snackBar.dismiss();
        }, 2000);
        break;
      }
      case 'rejected': {
        await this.storyService.updateApproval('rejected', story.id);
        this.snackBar.open('Story Rejected!');
        setTimeout(() => {
          this.snackBar.dismiss();
        }, 2000);
        break;
      }
    }
  }

  async editStory(id: string) {
    await this.router.navigate([`/admin/story/edit/${id}`, { fromQueue: true }]);
  }

  previousPage() {
    this.offset -= this.limit;
    this.getCurrentPage();
  }

  nextPage() {
    this.offset += this.limit;
    this.getCurrentPage();
  }

  getCurrentPage() {
    this.currentStoryPage = this.storiesForApproval.slice(this.offset, this.offset + this.limit);
  }
}
