import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AttractionSearchModel } from '../attraction.model';

@Component({
  selector: 'app-attraction-management',
  templateUrl: './attraction-management.component.html',
  styleUrls: ['./attraction-management.component.scss']
})
export class AttractionManagementComponent implements OnInit {

  constructor(
    private router: Router,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
  }

  goToAddAttraction() {
    this.router.navigate(['admin', 'attraction', 'add'], { queryParams: { fromMgmt: true }});
  }

  onSelectAttraction(attractionSearchObject: AttractionSearchModel) {
    this.router.navigate(['admin', 'attraction', 'edit', attractionSearchObject.objectID]);
  }
}
