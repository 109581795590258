import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoryFormComponent } from './story-form/story-form.component';
import { StoryComponent } from './story.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { StoryAddComponent } from './story-add/story-add.component';
import { StoryEditComponent } from './story-edit/story-edit.component';
import { StoryManagementComponent } from './story-management/story-management.component';
import { StoryVideoUploadComponent } from './story-video-upload/story-video-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { HttpClientModule } from '@angular/common/http';
import { MediaPreviewDirective } from '../shared/media-preview.directive';
import { AttractionFormComponent } from '../attraction/attraction-form/attraction-form.component';
import { AttractionModule } from '../attraction/attraction.module';
import { StoryVideoDialogComponent } from './story-video-dialog/story-video-dialog.component';

@NgModule({
  declarations: [
    StoryFormComponent,
    StoryComponent,
    StoryAddComponent,
    StoryEditComponent,
    StoryManagementComponent,
    StoryVideoUploadComponent,
    MediaPreviewDirective,
    StoryVideoDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    RouterModule,
    AgmCoreModule,
    MatGoogleMapsAutocompleteModule,
    FileUploadModule,
    HttpClientModule,
    AttractionModule
  ],
  entryComponents: [
    StoryVideoDialogComponent
  ]
})
export class StoryModule { }
