import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { StoryService } from '../story.service';
import { StoryModel } from '../story.model';
import { UserService } from '../../shared/user.service';
import { User } from 'firebase';

@Component({
  selector: 'app-story-management',
  templateUrl: './story-management.component.html',
  styleUrls: ['./story-management.component.scss']
})
export class StoryManagementComponent implements OnInit {

  userSearchForm = this.fb.group({
    user: null,
  });

  storySearchForm = this.fb.group({
    story: null,
  });

  userSearchSubscription: Subscription;
  userSearchResults: User[];
  userSelected: User;
  storySearchSubscription: Subscription;
  storySearchResults: StoryModel[];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private storyService: StoryService
  ) { }

  ngOnInit() {
    // Subscribe to the story search field changes
    this.storySearchSubscription = this.storySearchForm.controls.story.valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value: string) => {
      // Search for matching stories and show results
      console.log('Search value: ', value);
      this.storyService.search(value).then((data) => {
        console.log(data);
        this.storySearchResults = data;
      });
    });
  }

  goToStoryAdd() {
    this.router.navigate(['admin', 'story', 'add']);
  }

  goToStoryEdit(storyUid: string) {
    console.log(storyUid);
    this.router.navigate(['admin', 'story', 'edit', storyUid]) ;
  }

  /**
   * Display function for user lookup autocomplete
   * @param user Firebase user
   * @return Formatted user name/email display
   */
  userSearchDisplayFn(user: User): string {
    return !user ? undefined : user.displayName ? `${user.displayName} <${user.email}>` : 'Display name unavailable';
  }
}
