import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  /**
   * Check if user is logged in and is an admin
   * @param next
   * @param state
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    // TODO: Add a check for if a user has custom claim for admin
    if (this.authService.authenticated) { return true; }

    return this.authService.user.pipe(
      take(1),
      map(user => !!user),
      tap(loggedIn => {
        console.log('AuthGuard loggedIn: ', loggedIn)
        if (!loggedIn) {
          console.log('Access denied - user not logged in');
          this.router.navigate(['/admin', 'login']);
        }
      })
    );

  }
}
